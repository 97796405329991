import * as React from "react";
import Lightbox from "yet-another-react-lightbox";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import "./viewproperty.css";
// import "%URl_Public%/favicon.png";
import { Helmet } from "react-helmet-async";

import {
  Counter,
  Download,
  Fullscreen,
  Thumbnails,
  Zoom,
} from "yet-another-react-lightbox/plugins";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { list } from "../../../data/Data";

export function ViewProperty({ element }) {
  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    // استبدل `list` بمصدر البيانات الخاص بك
    const foundItem = list.find((listItem) => listItem.id === parseInt(id)); // تحقق من تحويل `id` إلى رقم صحيح إذا لزم الأمر
    setItem(foundItem);
  }, [id, list]);

  if (!item) {
    return <div>Loading...</div>; // يمكنك إضافة رسالة تحميل أو تصميم تحميل هنا
  }
  // دالة لتحويل النص المحاط بالنجوم (*) إلى نص غامق
  const convertToBold = (text) => {
    return text.replace(/\*(.*?)\*/g, "<strong>$1</strong>");
  };

  const dir = document.querySelector("html").getAttribute("dir");
  return (
    <>
      <Helmet>
        <meta
          property="og:url"
          content={`https://www.eilmalriyada.com/real-estate/view/${item.id}`}
        />
        <title>{dir === "rtl" ? item.name : item.name_en}</title>

        <meta
          property="og:title"
          content={dir === "rtl" ? item.name : item.name_en}
        />
        <meta
          property="og:description"
          content={dir === "rtl" ? item.location : item.location_en}
        />
        <meta property="og:image" content={item.cover} />

        <meta
          property="og:type"
          content={dir === "rtl" ? item.type : item.type_en}
        />

        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={dir === "rtl" ? item.name : item.name_en}
        />
        <meta
          name="twitter:description"
          content={dir === "rtl" ? item.location : item.location_en}
        />
        <meta name="twitter:image" content={item.cover} />
      </Helmet>
      <section className="viewpropery">
        <div className="container">
          <div className="view-img">
            <img src={item.cover} alt="" srcset="" />
            <div className="div-button">
              <button
                type="button"
                className="btn"
                onClick={() => setOpen(true)}
              >
                <i className="fa-solid fa-camera"></i>
                {dir !== "rtl" ? "View More Photos" : "معاينة المزيد من الصور"}
              </button>
              <a
                href={item.map_location}
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-solid fa-location-dot"></i>
                {dir !== "rtl" ? "Property Location" : "موقع العقار"}
              </a>
              <a href={item.filePdfRecent} className="hero-btn" download={item}>
                <i className="fa-solid fa-file-pdf"></i>
                {dir !== "rtl"
                  ? "Download the property file"
                  : "تحميل ملف العقار"}
              </a>
            </div>
          </div>
          <div
            className="containt"
            style={{
              [dir !== "rtl" ? "right" : "left"]: "8%",
            }}
          >
            <p>{dir === "rtl" ? item.price : item.price_en}</p>
            <span
              style={{
                background:
                  item.category_en === "For sale" ? "#25b5791a" : "#ff98001a",
                color: item.category_en === "For sale" ? "#25b579" : "#ff9800",
              }}
            >
              {dir !== "rtl" ? item.category_en : item.category}
            </span>
            <div className="content-option">
              <a href="https://wa.me/966508605772" className="whats">
                <i className="fa-brands fa-whatsapp"></i>
                {dir === "rtl" ? "واتساب" : "WhatsApp"}
              </a>
              <a href="mailto:baderalsiwan@gmail.com" className="email">
                <i className="fa-solid fa-envelope"></i>{" "}
                {dir === "rtl" ? "ايميل" : "Email"}
              </a>
            </div>
          </div>

          <div className="table-div ">
            <h1>{dir === "rtl" ? item.name : item.name_en}</h1>
            <p>{dir === "rtl" ? item.location : item.location_en}</p>
            <div>
              <table>
                <tr>
                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5 13.012V4C11.5 3.44772 11.9477 3 12.5 3H19.5C20.0523 3 20.5 3.44772 20.5 4V20.5C20.5 20.7761 20.2761 21 20 21C19.7239 21 19.5 20.7761 19.5 20.5V4H12.5V13.012H14.5C15.0523 13.012 15.5 13.4597 15.5 14.012V20.5C15.5 20.7761 15.2761 21 15 21C14.7239 21 14.5 20.7761 14.5 20.5V14.012H4.5V20.5C4.5 20.7761 4.27614 21 4 21C3.72386 21 3.5 20.7761 3.5 20.5V14.012C3.5 13.4597 3.94772 13.012 4.5 13.012H11.5ZM6.5 20.5C6.5 20.7761 6.27614 21 6 21C5.72386 21 5.5 20.7761 5.5 20.5V18.507C5.5 17.1262 6.61929 16.007 8 16.007C9.38071 16.007 10.5 17.1262 10.5 18.507V20.5C10.5 20.7761 10.2761 21 10 21C9.72386 21 9.5 20.7761 9.5 20.5V18.507C9.5 17.6785 8.82843 17.007 8 17.007C7.17157 17.007 6.5 17.6785 6.5 18.507V20.5ZM14.5 7C14.2239 7 14 6.77614 14 6.5C14 6.22386 14.2239 6 14.5 6H17.5C17.7761 6 18 6.22386 18 6.5C18 6.77614 17.7761 7 17.5 7H14.5ZM14.5 10C14.2239 10 14 9.77614 14 9.5C14 9.22386 14.2239 9 14.5 9H17.5C17.7761 9 18 9.22386 18 9.5C18 9.77614 17.7761 10 17.5 10H14.5Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl" ? "Property type:" : "نوع العقار:"}
                  </th>

                  <td>{dir !== "rtl" ? item.type_en : item.type}</td>
                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 19.2563L7.14783 18.1236C7.34385 17.9292 7.66043 17.9304 7.85493 18.1263C8.04943 18.3222 8.04819 18.6386 7.85217 18.833L5.85217 20.8084C5.65723 21.0017 5.34277 21.0017 5.14783 20.8084L3.14783 18.833C2.95181 18.6386 2.95057 18.3222 3.14507 18.1263C3.33957 17.9304 3.65615 17.9292 3.85217 18.1236L5 19.2563V9.6821L3.85217 10.8148C3.65615 11.0092 3.33957 11.008 3.14507 10.8121C2.95057 10.6162 2.95181 10.2998 3.14783 10.1054L5.14783 8.13006C5.34277 7.93677 5.65723 7.93677 5.85217 8.13006L7.85217 10.1054C8.04819 10.2998 8.04943 10.6162 7.85493 10.8121C7.66043 11.008 7.34385 11.0092 7.14783 10.8148L6 9.6821V19.2563ZM9.70978 5.99806L10.8433 7.14514C11.0378 7.34104 11.0365 7.65741 10.8405 7.85179C10.6445 8.04616 10.3279 8.04492 10.1334 7.84902L8.15674 5.85032C7.96332 5.65551 7.96332 5.34125 8.15674 5.14644L10.1334 3.14773C10.3279 2.95184 10.6445 2.9506 10.8405 3.14497C11.0365 3.33935 11.0378 3.65572 10.8433 3.85162L9.70978 4.9987H19.2902L18.1567 3.85162C17.9622 3.65572 17.9635 3.33935 18.1595 3.14497C18.3555 2.9506 18.6721 2.95184 18.8666 3.14773L20.8433 5.14644C21.0367 5.34125 21.0367 5.65551 20.8433 5.85032L18.8666 7.84902C18.6721 8.04492 18.3555 8.04616 18.1595 7.85179C17.9635 7.65741 17.9622 7.34104 18.1567 7.14514L19.2902 5.99806H9.70978ZM21 19.0013C21 20.1052 20.1046 21 19 21H11C9.89543 21 9 20.1052 9 19.0013V11.0065C9 9.90263 9.89543 9.00778 11 9.00778H19C20.1046 9.00778 21 9.90263 21 11.0065V13.0052H20V11.0065C20 10.4546 19.5523 10.0071 19 10.0071H11C10.4477 10.0071 10 10.4546 10 11.0065V19.0013C10 19.5532 10.4477 20.0006 11 20.0006H19C19.5523 20.0006 20 19.5532 20 19.0013H21ZM21 17.0026H20V15.0039H21V17.0026Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl" ? "Property area:" : "مساحة العقار:"}
                  </th>
                  <td>{item.property_area}</td>
                </tr>
                <tr>
                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12ZM21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3C16.9706 3 21 7.02944 21 12ZM16.4552 8.2069C16.5414 8.01716 16.5009 7.79382 16.3536 7.64645C16.2062 7.49907 15.9828 7.45857 15.7931 7.54482L10.2931 10.0448C10.183 10.0948 10.0948 10.183 10.0448 10.2931L7.54482 15.7931C7.45857 15.9828 7.49907 16.2062 7.64645 16.3536C7.79382 16.5009 8.01716 16.5414 8.2069 16.4552L13.7069 13.9552C13.817 13.9052 13.9052 13.817 13.9552 13.7069L16.4552 8.2069ZM9.00692 14.9931L10.8776 10.8776L14.9931 9.00692L13.1224 13.1224L9.00692 14.9931Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl" ? "Street direction:" : "اتجاه الشارع:"}
                  </th>
                  <td>
                    {dir !== "rtl"
                      ? item.street_direction_en
                      : item.street_direction}
                  </td>

                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M5.5 9.90832V7.4753C5.5 6.66051 6.16057 6 6.99513 6C7.82969 6 8.5 6.66051 8.5 7.4753V8.13266C8.5 8.40139 8.27687 8.61923 8.00162 8.61923C7.72638 8.61923 7.50324 8.40139 7.50324 8.13266V7.4753C7.50324 7.19796 7.2792 6.97314 6.99513 6.97314C6.71106 6.97314 6.49676 7.19796 6.49676 7.4753V9.90832H12.5C12.5 9.36869 12.9477 8.93124 13.5 8.93124H16.5C17.0523 8.93124 17.5 9.36869 17.5 9.90832H19.5C20.0523 9.90832 20.5 10.3458 20.5 10.8854V13.3281C20.5 15.1507 19.0407 16.6403 17.2014 16.7423L17.6351 17.166C17.8303 17.3568 17.8303 17.6661 17.6351 17.8569C17.4398 18.0477 17.1232 18.0477 16.9279 17.8569L15.7929 16.7479H8.48861L7.35355 17.8569C7.15829 18.0477 6.84171 18.0477 6.64645 17.8569C6.45118 17.6661 6.45118 17.3568 6.64645 17.166L7.0744 16.7479H7C5.067 16.7479 3.5 15.2168 3.5 13.3281V10.8854C3.5 10.3458 3.94772 9.90832 4.5 9.90832H5.5ZM13.5 9.90832V10.8854H16.5V9.90832H13.5ZM12.5 10.8854H4.5V13.3281C4.5 14.6772 5.61929 15.7708 7 15.7708H17C18.3807 15.7708 19.5 14.6772 19.5 13.3281V10.8854H17.5V13.8166C17.5 14.3563 17.0523 14.7937 16.5 14.7937H13.5C12.9477 14.7937 12.5 14.3563 12.5 13.8166V10.8854ZM13.5 10.8854V13.8166H16.5V12.8396H14.7092C14.4331 12.8396 14.2092 12.6208 14.2092 12.351C14.2092 12.0812 14.4331 11.8625 14.7092 11.8625H16.5V10.8854H13.5Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl" ? "Bathrooms:" : "الحمامات:"}
                  </th>
                  <td>
                    <td>{item.bathrooms}</td>
                  </td>
                </tr>
                <tr>
                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M17.2929 18H7.70711L6.85355 18.8536C6.65829 19.0488 6.34171 19.0488 6.14645 18.8536C5.95118 18.6583 5.95118 18.3417 6.14645 18.1464L6.29289 18H6C4.89543 18 4 17.1046 4 16V15C4 13.8954 4.89543 13 6 13H7.26756C7.09739 12.7058 7 12.3643 7 12C7 10.8954 7.89543 10 9 10H11C11.5973 10 12.1335 10.2619 12.5 10.6771C12.8665 10.2619 13.4027 10 14 10H16C17.1046 10 18 10.8954 18 12C18 12.3643 17.9026 12.7058 17.7324 13H19C20.1046 13 21 13.8954 21 15V16C21 17.1046 20.1046 18 19 18H18.7071L18.8536 18.1464C19.0488 18.3417 19.0488 18.6583 18.8536 18.8536C18.6583 19.0488 18.3417 19.0488 18.1464 18.8536L17.2929 18ZM19 14H6C5.44772 14 5 14.4477 5 15V16C5 16.5523 5.44772 17 6 17H19C19.5523 17 20 16.5523 20 16V15C20 14.4477 19.5523 14 19 14ZM16 13C16.5523 13 17 12.5523 17 12C17 11.4477 16.5523 11 16 11H14C13.4477 11 13 11.4477 13 12C13 12.5523 13.4477 13 14 13H16ZM9 13H11C11.5523 13 12 12.5523 12 12C12 11.4477 11.5523 11 11 11H9C8.44772 11 8 11.4477 8 12C8 12.5523 8.44772 13 9 13ZM6 11.5C6 11.7761 5.77614 12 5.5 12C5.22386 12 5 11.7761 5 11.5V8.5C5 7.1384 5.9375 6 7.14706 6H17.8529C19.0625 6 20 7.1384 20 8.5V11.5C20 11.7761 19.7761 12 19.5 12C19.2239 12 19 11.7761 19 11.5V8.5C19 7.65246 18.4627 7 17.8529 7H7.14706C6.53732 7 6 7.65246 6 8.5V11.5Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl" ? "Number of Bedrooms:" : "عدد غرف النوم:"}
                  </th>

                  <td>{item.bedrooms}</td>
                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 19.2563L7.14783 18.1236C7.34385 17.9292 7.66043 17.9304 7.85493 18.1263C8.04943 18.3222 8.04819 18.6386 7.85217 18.833L5.85217 20.8084C5.65723 21.0017 5.34277 21.0017 5.14783 20.8084L3.14783 18.833C2.95181 18.6386 2.95057 18.3222 3.14507 18.1263C3.33957 17.9304 3.65615 17.9292 3.85217 18.1236L5 19.2563V9.6821L3.85217 10.8148C3.65615 11.0092 3.33957 11.008 3.14507 10.8121C2.95057 10.6162 2.95181 10.2998 3.14783 10.1054L5.14783 8.13006C5.34277 7.93677 5.65723 7.93677 5.85217 8.13006L7.85217 10.1054C8.04819 10.2998 8.04943 10.6162 7.85493 10.8121C7.66043 11.008 7.34385 11.0092 7.14783 10.8148L6 9.6821V19.2563ZM9.70978 5.99806L10.8433 7.14514C11.0378 7.34104 11.0365 7.65741 10.8405 7.85179C10.6445 8.04616 10.3279 8.04492 10.1334 7.84902L8.15674 5.85032C7.96332 5.65551 7.96332 5.34125 8.15674 5.14644L10.1334 3.14773C10.3279 2.95184 10.6445 2.9506 10.8405 3.14497C11.0365 3.33935 11.0378 3.65572 10.8433 3.85162L9.70978 4.9987H19.2902L18.1567 3.85162C17.9622 3.65572 17.9635 3.33935 18.1595 3.14497C18.3555 2.9506 18.6721 2.95184 18.8666 3.14773L20.8433 5.14644C21.0367 5.34125 21.0367 5.65551 20.8433 5.85032L18.8666 7.84902C18.6721 8.04492 18.3555 8.04616 18.1595 7.85179C17.9635 7.65741 17.9622 7.34104 18.1567 7.14514L19.2902 5.99806H9.70978ZM21 19.0013C21 20.1052 20.1046 21 19 21H11C9.89543 21 9 20.1052 9 19.0013V11.0065C9 9.90263 9.89543 9.00778 11 9.00778H19C20.1046 9.00778 21 9.90263 21 11.0065V13.0052H20V11.0065C20 10.4546 19.5523 10.0071 19 10.0071H11C10.4477 10.0071 10 10.4546 10 11.0065V19.0013C10 19.5532 10.4477 20.0006 11 20.0006H19C19.5523 20.0006 20 19.5532 20 19.0013H21ZM21 17.0026H20V15.0039H21V17.0026Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl"
                      ? "Length of Land Piece:"
                      : "طول قطعة الأرض:"}
                  </th>
                  <td>{item.land_length}</td>
                </tr>
                <tr>
                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M11.5 13.012V4C11.5 3.44772 11.9477 3 12.5 3H19.5C20.0523 3 20.5 3.44772 20.5 4V20.5C20.5 20.7761 20.2761 21 20 21C19.7239 21 19.5 20.7761 19.5 20.5V4H12.5V13.012H14.5C15.0523 13.012 15.5 13.4597 15.5 14.012V20.5C15.5 20.7761 15.2761 21 15 21C14.7239 21 14.5 20.7761 14.5 20.5V14.012H4.5V20.5C4.5 20.7761 4.27614 21 4 21C3.72386 21 3.5 20.7761 3.5 20.5V14.012C3.5 13.4597 3.94772 13.012 4.5 13.012H11.5ZM6.5 20.5009C6.5 20.777 6.27614 21.0009 6 21.0009C5.72386 21.0009 5.5 20.777 5.5 20.5009V18.5078C5.5 17.1271 6.61929 16.0078 8 16.0078C9.38071 16.0078 10.5 17.1271 10.5 18.5078V20.5009C10.5 20.777 10.2761 21.0009 10 21.0009C9.72386 21.0009 9.5 20.777 9.5 20.5009V18.5078C9.5 17.6794 8.82843 17.0078 8 17.0078C7.17157 17.0078 6.5 17.6794 6.5 18.5078V20.5009ZM14 6.5C14 6.77614 14.2239 7 14.5 7H17.5C17.7761 7 18 6.77614 18 6.5C18 6.22386 17.7761 6 17.5 6H14.5C14.2239 6 14 6.22386 14 6.5ZM14.5 10C14.2239 10 14 9.77614 14 9.5C14 9.22386 14.2239 9 14.5 9H17.5C17.7761 9 18 9.22386 18 9.5C18 9.77614 17.7761 10 17.5 10H14.5ZM6.13181 5.33023L6.5 4.52568L6.86819 5.33023C7.03052 5.68496 7.31504 5.96948 7.66977 6.13181L8.47432 6.5L7.66977 6.86819C7.31504 7.03052 7.03052 7.31504 6.86819 7.66977L6.5 8.47432L6.13181 7.66977C5.96948 7.31504 5.68496 7.03052 5.33023 6.86819L4.52568 6.5L5.33023 6.13181C5.68496 5.96948 5.96948 5.68496 6.13181 5.33023ZM5.93137 3.36512C6.15416 2.87829 6.84584 2.87829 7.06863 3.36512L7.77749 4.9141C7.83995 5.05058 7.94942 5.16005 8.0859 5.22251L9.63488 5.93137C10.1217 6.15416 10.1217 6.84584 9.63488 7.06863L8.0859 7.77749C7.94942 7.83995 7.83995 7.94942 7.77749 8.0859L7.06863 9.63488C6.84584 10.1217 6.15416 10.1217 5.93137 9.63488L5.22251 8.0859C5.16005 7.94942 5.05058 7.83995 4.9141 7.77749L3.36512 7.06863C2.87829 6.84584 2.87829 6.15416 3.36512 5.93137L4.9141 5.22251C5.05058 5.16005 5.16005 5.05058 5.22251 4.9141L5.93137 3.36512Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl" ? "Property Age:" : "عمر العقار:"}
                  </th>
                  <td>
                    {dir !== "rtl" ? item.property_age_en : item.property_age}
                  </td>
                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M9.13736 3.01924C9.40288 3.09511 9.55662 3.37185 9.48076 3.63737L6.48076 14.1374C6.4049 14.4029 6.12816 14.5566 5.86264 14.4808C5.59712 14.4049 5.44338 14.1282 5.51924 13.8626L8.51924 3.36265C8.5951 3.09713 8.87184 2.94338 9.13736 3.01924ZM14.8626 3.01924C15.1282 2.94338 15.4049 3.09713 15.4808 3.36265L18.4808 13.8626C18.5566 14.1282 18.4029 14.4049 18.1374 14.4808C17.8718 14.5566 17.5951 14.4029 17.5192 14.1374L14.5192 3.63737C14.4434 3.37185 14.5971 3.09511 14.8626 3.01924ZM6.85355 16.1465C7.04882 16.3417 7.04882 16.6583 6.85355 16.8536L5.70711 18H18.2929L17.1464 16.8536C16.9512 16.6583 16.9512 16.3417 17.1464 16.1465C17.3417 15.9512 17.6583 15.9512 17.8536 16.1465L19.8536 18.1465C20.0488 18.3417 20.0488 18.6583 19.8536 18.8536L17.8536 20.8536C17.6583 21.0488 17.3417 21.0488 17.1464 20.8536C16.9512 20.6583 16.9512 20.3417 17.1464 20.1465L18.2929 19H5.70711L6.85355 20.1465C7.04882 20.3417 7.04882 20.6583 6.85355 20.8536C6.65829 21.0488 6.34171 21.0488 6.14645 20.8536L4.14645 18.8536C3.95118 18.6583 3.95118 18.3417 4.14645 18.1465L6.14645 16.1465C6.34171 15.9512 6.65829 15.9512 6.85355 16.1465ZM12.5 4.00001C12.5 3.72386 12.2761 3.50001 12 3.50001C11.7239 3.50001 11.5 3.72386 11.5 4.00001V5.49915C11.5 5.77529 11.7239 5.99915 12 5.99915C12.2761 5.99915 12.5 5.77529 12.5 5.49915V4.00001ZM12.5 8.00001C12.5 7.72386 12.2761 7.50001 12 7.50001C11.7239 7.50001 11.5 7.72386 11.5 8.00001V9.50042C11.5 9.77656 11.7239 10.0004 12 10.0004C12.2761 10.0004 12.5 9.77656 12.5 9.50042V8.00001ZM12.5 12C12.5 11.7239 12.2761 11.5 12 11.5C11.7239 11.5 11.5 11.7239 11.5 12V13.5004C11.5 13.7766 11.7239 14.0004 12 14.0004C12.2761 14.0004 12.5 13.7766 12.5 13.5004V12Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl" ? "Street Width:" : "عرض الشارع:"}
                  </th>
                  <td>{item.street_width}</td>
                </tr>
                <tr>
                  <th>
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6 19.2563L7.14783 18.1236C7.34385 17.9292 7.66043 17.9304 7.85493 18.1263C8.04943 18.3222 8.04819 18.6386 7.85217 18.833L5.85217 20.8084C5.65723 21.0017 5.34277 21.0017 5.14783 20.8084L3.14783 18.833C2.95181 18.6386 2.95057 18.3222 3.14507 18.1263C3.33957 17.9304 3.65615 17.9292 3.85217 18.1236L5 19.2563V9.6821L3.85217 10.8148C3.65615 11.0092 3.33957 11.008 3.14507 10.8121C2.95057 10.6162 2.95181 10.2998 3.14783 10.1054L5.14783 8.13006C5.34277 7.93677 5.65723 7.93677 5.85217 8.13006L7.85217 10.1054C8.04819 10.2998 8.04943 10.6162 7.85493 10.8121C7.66043 11.008 7.34385 11.0092 7.14783 10.8148L6 9.6821V19.2563ZM9.70978 5.99806L10.8433 7.14514C11.0378 7.34104 11.0365 7.65741 10.8405 7.85179C10.6445 8.04616 10.3279 8.04492 10.1334 7.84902L8.15674 5.85032C7.96332 5.65551 7.96332 5.34125 8.15674 5.14644L10.1334 3.14773C10.3279 2.95184 10.6445 2.9506 10.8405 3.14497C11.0365 3.33935 11.0378 3.65572 10.8433 3.85162L9.70978 4.9987H19.2902L18.1567 3.85162C17.9622 3.65572 17.9635 3.33935 18.1595 3.14497C18.3555 2.9506 18.6721 2.95184 18.8666 3.14773L20.8433 5.14644C21.0367 5.34125 21.0367 5.65551 20.8433 5.85032L18.8666 7.84902C18.6721 8.04492 18.3555 8.04616 18.1595 7.85179C17.9635 7.65741 17.9622 7.34104 18.1567 7.14514L19.2902 5.99806H9.70978ZM21 19.0013C21 20.1052 20.1046 21 19 21H11C9.89543 21 9 20.1052 9 19.0013V11.0065C9 9.90263 9.89543 9.00778 11 9.00778H19C20.1046 9.00778 21 9.90263 21 11.0065V13.0052H20V11.0065C20 10.4546 19.5523 10.0071 19 10.0071H11C10.4477 10.0071 10 10.4546 10 11.0065V19.0013C10 19.5532 10.4477 20.0006 11 20.0006H19C19.5523 20.0006 20 19.5532 20 19.0013H21ZM21 17.0026H20V15.0039H21V17.0026Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </th>
                  <th className="th">
                    {dir !== "rtl" ? "Land Width:" : "عرض قطعة الأرض:"}
                  </th>
                  <td colSpan={3}>{item.land_width}</td>
                </tr>
              </table>
            </div>
            <hr />
            <div className="desc-more">
              <h4>{dir !== "rtl" ? "Description" : "الوصف"}</h4>
              <pre
                className="pre-wrap"
                dangerouslySetInnerHTML={{
                  __html:
                    dir !== "rtl"
                      ? convertToBold(item.description_en)
                      : convertToBold(item.description),
                }}
              ></pre>
            </div>
            <hr />
            <h4>
              {dir !== "rtl" ? "License Number" : "رقم الترخيص"} :{" "}
              {item.license_number}
            </h4>
          </div>

          <Lightbox
            plugins={[
              Captions,
              Zoom,
              Fullscreen,
              Download,
              Thumbnails,
              Counter,
            ]}
            open={open}
            counter={{
              container: {
                style: { top: "0", bottom: "auto", left: "auto", right: "0" },
              },
            }}
            close={() => setOpen(false)}
            slides={item.imgs}
          />
        </div>
      </section>
    </>
  );
}
